<template>

	<div class="base-button" @click="redirectLink()" :class="{small, paused, loading, inverted, outline, 'disabled-btn': disabled, 'button-with-icon': iconLeft||iconRight }" >
		<div v-if="iconLeft" class="button-icon pr-10">
			<img v-if="iconLeft !== 'chevron'" :alt="'icon '+iconLeft" :src="leftIconSrc"/>
			<AppChevronIcon v-if="iconLeft === 'chevron'" direction="left" :color="finalIconColor"/>
		</div>
		<div class="button-inner">
			<div class="btn-text"><p>{{ text }}</p></div>
		</div>
		<div v-if="iconRight" class="button-icon pl-10">
			<img v-if="iconRight !== 'chevron'" :alt="'icon '+iconRight" :src="rightIconSrc"/>
			<AppChevronIcon v-if="iconRight === 'chevron'" direction="right" :color="finalIconColor"/>
		</div>
		<div class="button-loader-container" v-if="loading">
			<AppLoader :color="loaderColor" :size="14" />
		</div>
	</div>

</template>

<script>
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import AppLoader from "@/shared/components/AppLoader.vue";
import {useUserStore} from "@/console-new/stores";

export default {
	setup() {
		let userStore = useUserStore();
		return {userStore};
	},
	name: "AppButton",
	components: {AppChevronIcon, AppLoader},
	props: {
		text: {type: String, required: true},
		iconLeft: {type: String, default: null},
		iconRight: {type: String, default: null},
		inverted: {type: Boolean, default: false},
		outline: {type: Boolean, default: false},
		href: {type: String, default: null},
		small: {type: Boolean, default: false},
		disabled: {type: Boolean, default: false},
		paused: {type: Boolean, default: false},
		loading: {type: Boolean, default: false},
	},
	methods: {
		redirectLink() {
			if (this.href) {
				window.location.href = this.href;
			}
		}
	},
	computed: {
		finalIconColor() {
			let color = this.inverted ? '#0752A3' : '#2CECBE';
			if (this.outline) {
				color = this.inverted ? '#2CECBE' : '#0752A3';
			}
			return color;
		},
		loaderColor() {
			let color = this.inverted ? 'var(--color-primary)' : 'var(--color-secondary)';
			if (this.outline) {
				color = this.inverted ? 'var(--color-secondary)' : 'var(--color-primary)';
			}
			return color;

		},
		leftIconSrc() {
			return this.iconLeft ? new URL('https://img.qromo.io/img/icons/'+this.iconLeft+(this.userStore.darkMode ? '-dark' : '')+'.svg', import.meta.url).href : null;
		},
		rightIconSrc() {
			return this.iconRight ? new URL('https://img.qromo.io/img/icons/'+this.iconRight+(this.userStore.darkMode ? '-dark' : '')+'.svg', import.meta.url).href : null;
		},
	},
}
</script>

<style lang="scss" scoped>

.base-button {
	@include centered();
	width: fit-content;
	min-width: 100px;
	max-width: 500px;
	padding: 5px 22px;
	border-radius: 13px;
	background-color: var(--color-primary);
	cursor: pointer;
	pointer-events: all;
	position: relative;
	transition: border-radius .2s $transition, box-shadow .3s $transition, filter .3s $transition, background-color .3s $transition, color .3s $transition, border .3s $transition;

	&.paused {
		pointer-events: none;
	}

	.button-icon {
		@include centered();
		max-width: 25px;
		pointer-events: none;

		svg {
			max-width: 15px;
			max-height: 15px;
		}

		img {
			max-width: 15px;
			max-height: 15px;
			width: 15px;
		}
	}

	.button-inner {
		max-width: 100%;
	}

	&.loading {
		pointer-events: none;
		.button-icon, .button-inner {
			opacity: 0;
		}
	}

	&:hover {
		box-shadow: var(--color-shadow-button) 3px 3px 8px 1px, var(--color-light-shadow-button) -3px -3px 10px 2px;
		filter: brightness(1.05);
	}

	&:active {
		border-radius: 15px;
		box-shadow: var(--color-shadow-button) 3px 3px 8px 1px, var(--color-light-shadow-button) -3px -3px 10px 2px;
	}

	&, & * {
		@include noSelect();
	}

	.btn-text {
		max-width: 100%;

		p {
			@include truncateString();
			color: $secondary;
			font-weight: 500;
			font-size: 16px;
			text-align: center;
			line-height: normal;
			transition: color .3s $transition;
		}
	}

	&.inverted {
		background-color: var(--color-secondary);

		.btn-text p {
			color: var(--color-primary);
		}
	}

	&.outline {
		background-color: transparent;

		.btn-text p { color: var(--color-primary); }

		&.inverted  .btn-text p { color: var(--color-secondary); }
	}
	&.outline {
		border: 1px solid var(--color-primary);
		&.inverted { border: 1px solid var(--color-secondary); }
	}

	&.disabled-btn {
		cursor: not-allowed;
		pointer-events: none;
		&:not(.outline) {
			outline-color: transparent;
			color: var(--color-bg);
			background-color: var(--color-bg-primary-1);
			.btn-text p { color: var(--color-txt-2); }
			:deep(svg) path.secondary { fill: var(--color-bg); }
		}

		&.outline {
			color: var(--color-bg-primary-3);
			background-color: var(--color-bg);
			border: 1px solid var(--color-bg-primary-2);
			.btn-text p { color: var(--color-bg-primary-2); }
		}
	}

	&.small {
		padding: 9px 7px;
		min-width: 100px;
		.btn-text p {
			font-size: 12px;
			line-height: 14px;
		}
	}

	.button-loader-container {
		@include centered();
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 768px) {
		padding: 5px 22px;

		.btn-text p {
			line-height: 20px;
		}
	}

}

</style>